@use '../../styles/mixins' as *;
@use '../../styles/variables' as *;

.section-footer {
	width: 100%;
	margin: 5rem 0 2rem;
	@include desktop-sm {
		margin-top: 3rem;
	}
	&__title {
		font-size: $fs-300;
		font-family: 'Titillium Web', sans-serif;
		line-height: 1;
		font-size: 2.5rem;
	}

	&__contact {
		display: flex;
		flex-direction: column;
		align-items: flex-start;

		&--link {
			display: block;
			font-size: $fs-150;
			font-weight: $fw-300;
			color: var(--primary);
			margin-top: 2rem;

			&:hover {
				text-decoration: underline;
			}

			&:last-child {
				margin-top: 0;
			}

			@include desktop-sm {
				font-size: $fs-200;
			}
		}
	}

	&__copyright {
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-top: solid 1px var(--primary);
		padding-top: 1rem;
		flex-direction: column;
		align-items: flex-start;
		gap: 1rem;
		margin: 3rem 0 0;

		@include tablet {
			flex-direction: row;
			align-items: center;
		}

		p {
			font-size: clamp(0.8rem, 2vw, 1rem);
		}
	}

	&__social {
		display: flex;
		gap: 1rem;
		flex-direction: row;
		align-items: center;
		justify-content: flex-end;

		@include desktop-sm {
			gap: 2;
		}

		&--title {
			font-weight: $fw-400;
			font-size: clamp(0.8rem, 2vw, 1.05rem);
		}

		&--link {
			width: 40px;
			height: 40px;
			border-radius: 50%;
			background-color: var(--social-color);
			display: flex;
			align-items: center;
			justify-content: center;
			transition: all ease 0.5s;
			@include desktop-sm {
				width: 40px;
				height: 40px;
			}

			&:hover {
				background-color: var(--social-color-hover);
			}
		}

		&--icon {
			color: var(--bg-color);
			font-size: $fs-150;

			@include desktop-sm {
				font-size: $fs-150;
			}
		}
	}
}
