@use '../../styles/mixins' as *;
@use '../../styles/variables' as *;

.contact-form {
	width: 100%;
	margin-top: 1.5rem;
	@include tablet {
		margin-bottom: 10rem;
		max-width: 600px;
	}

	&__title {
		font-size: 1.1rem;
		font-family: inherit;

		@include desktop-sm {
			font-size: 1.5rem;
		}
	}

	&__form {
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		margin-top: 1rem;
	}

	&__group {
		display: flex;

		&:last-child {
			@include desktop-sm {
				margin-top: 0;
			}
		}

		& .contact-form__label {
			font-weight: bold;
			flex: 1 0 140px;
		}

		& .contact-form__input,
		& .contact-form__textarea {
			padding: 17px;
			margin-top: 5px;
			margin-bottom: 10px;
			border: solid 1px var(--primary);
			width: 100%;
			font-family: inherit;
			color: var(--primaryLightBlue);
			font-size: $fs-100;
			border-radius: 0;
			transition: border-color 0.3s ease;
		}

		& .contact-form__textarea {
			resize: none;
			height: 150px;
		}

		& .contact-form__input:active,
		& .contact-form__textarea:active {
			border-color: var(--primaryLightBlue);
			box-shadow: 0 0 5px var(--primaryLightBlue);
		}

		::placeholder {
			font-size: $fs-100;
			color: var(--primary);
		}
	}

	&__submit {
		font-size: $fs-100;
		background-color: var(--btn-bg);
		color: var(--btn-text);
		border-radius: 1rem;
		border: none;
		padding: 0.6rem 1.5rem;
		transition: all 0.2s ease;
		font-weight: $fw-600;
		cursor: pointer;
		font-family: inherit;
		margin-top: 1rem;

		&:hover {
			@include hover;
		}
	}

	&__validator {
		font-size: 1rem;
		background-color: var(--primaryLightGreen);
		font-weight: $fw-700;
		padding: 0.1rem 0.3rem;
		color: var(--primaryLightBlue);
		margin-top: 1rem;
		position: absolute;
		border-radius: 0.2rem;
	}
}
