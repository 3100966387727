@use '../../styles/mixins' as *;
@use '../../styles/variables' as *;

.skills {
	margin: 4rem 0;

	@include tablet {
		display: grid;
		grid-template-columns: 1fr 1fr;
		// gap: 3rem;
		margin-top: 5rem;
	}
}

.skills-container {
	// display: flex;
	// flex-direction: column;
	// gap: 2rem;

	display: flex;
	flex-direction: row;
	gap: 2rem;
	justify-content: flex-start;
	flex-wrap: wrap;
	align-items: flex-start;
	align-content: flex-start;
	margin-bottom: 3rem;

	&__group {
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		// gap: 1rem;
		// border-bottom: solid 1px var(--primary);
		// padding-bottom: 2rem;

		&:first-child {
			@include desktop-sm {
				margin-top: 0;
			}
		}

		&:last-child {
			border-bottom: none;
		}

		&--image {
			scale: 4;
			color: var(--icon-color);
			margin-top: 3rem;
			margin-left: 1.5rem;
		}
	}

	&__text {
		display: flex;
		flex-direction: column;
		flex: 2;
		line-height: 1.3;
		margin-left: 2rem;
		gap: 0.2rem;

		&--title {
			font-size: $fs-110;
		}

		&--body {
			font-weight: $fw-300;

			@include tablet {
				font-size: 1rem;
			}
		}
	}
}

.skills-progress {
	margin-top: 1rem;
	padding-top: 3rem;
	border-top: 1px solid var(--primary);

	@include tablet {
		border-top: none;
		border-left: 1px solid var(--primary);
		padding-left: 3rem;
		padding-top: 0;
		flex: 1;
	}

	@include desktop-sm {
		margin-top: 0.5rem;
	}
}
