@use '../../styles/mixins' as *;
@use '../../styles/variables' as *;

.mainproject {
	margin: 2rem 0;
	padding: 1rem;
	background: var(--project-color);
	box-shadow: 0 0 30px rgba(0, 0, 0, 0.15);
	border-radius: 10px;
	background: rgb(243, 244, 246);
	gap: 2rem;

	@include tablet {
		margin: 3rem 0;
	}

	@include desktop-sm {
		display: flex;
		flex-direction: row;
	}

	&__figure {
		@include desktop-sm {
			flex: 4;
		}
		&--img {
			background-size: cover;
			aspect-ratio: 16/9;
		}
	}

	&__text {
		margin-top: 1.5rem;

		@include desktop-sm {
			margin-top: 0;
			flex: 2;
		}

		&--icon {
			color: var(--primary);

			&:hover {
				color: var(--primaryLightGreen);
			}
		}

		&--link {
			margin-top: 0.3rem;
			margin-left: 0.5rem;
		}

		&--title {
			margin-top: 0.5rem;
			font-size: 1.3rem;

			@include tablet {
				font-size: 1.3rem;
			}
		}

		&--body {
			font-weight: $fw-300;
			line-height: 1.3;
			margin-bottom: 1rem;
			font-size: 1rem;
		}
	}
}

// .carousel {
// 	outline: solid 1px var(--primary);
// 	box-shadow: 5px 5px 0px var(--primary);
// }

.carousel .control-arrow:hover {
	background-color: var(--primaryLightBlue) !important;
}

.carousel .slide img {
	max-width: 100%;
	height: auto;
}

.carousel .control-dots {
	z-index: 0 !important;
}

.carousel .control-dots .dot {
	box-shadow: none !important;
	background-color: var(--primaryLightBlue) !important;
}

.carousel .control-arrow,
.carousel.carousel-slider .control-arrow {
	z-index: 0 !important;
}

.carousel .control-prev.control-arrow,
.carousel .control-next.control-arrow {
	opacity: 1;
	visibility: visible;
}

.carousel .control-prev.control-arrow:before,
.carousel .control-next.control-arrow:before {
	font-size: 26px;
}
