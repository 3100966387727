@use '../../styles/mixins' as *;
@use '../../styles/variables' as *;

.tabwork {
	&-section {
		@include desktop-sm {
			display: flex;
			align-items: flex-start;
			gap: 3rem;
		}
	}
	&-title {
		font-size: $fs-150;
		margin-bottom: 1rem;
		font-family: $ff-web;
	}
	&-buttons {
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		align-items: stretch;
		gap: 0.7rem;

		@include desktop-sm {
			flex: 1;
		}
	}

	&-button {
		font-size: $fs-100;
		font-weight: $fw-400;
		font-family: inherit;
		background-color: var(--tab-color);
		padding: 1rem;
		text-align: left;
		display: flex;
		color: var(--primary);
		transition: all ease 0.3s;

		&.active {
			border-left: solid 10px var(--tab-color-active);
			color: var(--primary);
			font-weight: 700;
		}
		&:hover {
			color: var(--primary);
		}
	}

	&-content {
		margin-top: 2rem;
		padding: 0;

		@include desktop-sm {
			height: auto;
			flex: 3;
			margin-top: 0;
			margin-bottom: 5rem;
		}
	}
}
