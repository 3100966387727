@use '../../styles/mixins' as *;
@use '../../styles/variables' as *;

.intro {
	display: flex;
	flex-grow: 1;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;
	gap: 3rem;
	height: auto;
	padding-top: 2rem;

	@include tablet {
		flex-direction: row;
		align-content: space-around;
		margin: 8rem auto 0 auto;
		align-items: flex-start;
	}

	@include desktop {
		gap: 7rem;
		padding: 0;
		align-items: center;
		transition: all 0.5s ease;
		height: 100dvh;
		margin-top: -3rem;
	}

	&__info {
		flex: 3;
		order: 2;

		@include tablet {
			order: initial;
		}

		&--title {
			font-family: 'Titillium Web', sans-serif;
			margin-bottom: 1rem;
			line-height: 0.9;
			font-size: clamp(2.2rem, 9vw, 4rem);
			color: var(--primary);

			@include desktop-sm {
				font-size: clamp(1.3rem, 9vw, 5rem);
			}

			span {
				color: var(--btn-bg);
			}

			@include desktop-sm {
				font-size: 4rem;
				text-align: left;
			}
		}

		&--text {
			font-family: 'Josefin Sans', sans-serif;
			font-size: $fs-120;
			font-weight: $fw-300;
			margin-top: 1rem;
			display: block;

			@include desktop-sm {
				text-align: left;
				font-size: 1.4rem;
				padding: 0;
				margin-top: 1rem;
			}
		}
	}

	&__placeholder {
		flex: 1;
		display: flex;
		justify-content: center;
		justify-content: flex-end;

		@include tablet {
			margin-top: 0;
		}

		&--img {
			width: 250px;
			height: 250px;
			border-radius: 50%;
			overflow: hidden;
			transition: all ease-in 0.5s;

			@include desktop {
				width: 300px;
				height: 300px;
			}

			img {
				max-width: 100%;
				max-height: 100%;
				object-fit: cover;
				object-position: center center;
			}
		}
	}
}

.read-more-btn {
	font-family: inherit;
	font-size: $fs-090;
	font-weight: $fw-700;
	color: var(--primary);
	margin-top: 1rem;
	display: block;

	@include desktop-sm {
		display: none;
	}
}
