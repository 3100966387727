@use '../../styles/mixins' as *;
@use '../../styles/variables' as *;

.button {
	@include button;

	@include tablet {
		&:hover {
			@include hover;
		}
	}
}
