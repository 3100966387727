//Breakpoints
$mobile-breakpoint: 480px;
$tablet-breakpoint: 720px;
$desktop-sm-breakpoint: 940px;
$desktop-breakpoint: 1280px;

// Root colours
:root {
	--primary: rgba(51, 64, 81);
	--primaryLightGreen: #8fd1c2;
	--primaryLightBlue: #455d7a;
	--bg-color: #ffffff;
	--header-color: #f3f6fb;
	--project-color: #f8f8f8;
	--btn-color: #eaeaea;
	--btn-text: #fff;
	--btn-bg: #455d7a;
	--btn-hover: #8fd1c2;
	--social-color: rgba(51, 64, 81);
	--social-color-hover: #8fd1c2;
	--tab-color: rgba(51, 64, 81, 0.2);
	--tab-color-active: #455d7a;
	--project-bg: rgba(255, 255, 255, 0);
	--navbar-color: #eaeaea;
	--icon-color: #455d7a;
	--ico-hover: #dbdee3;
	--ico-bg: #e1e4e9;
	--bubbles-light-color: #9db0ce;
	--bubbles-dark-color: #455d7a;
	--highlight-color: #8fd1c2;
	--highlight-color-text: rgba(51, 64, 81);
}

// Dark colours
[data-theme='dark'] {
	--primary: #ffffff;
	--primaryLightGreen: #8fd1c2;
	--primaryLightBlue: #455d7a;
	--bg-color: #334051;
	--header-color: #1a2b3a;
	--project-color: #1a2b3a;
	--btn-color: #eaeaea;
	--btn-text: rgba(51, 64, 81);
	--btn-bg: #8fd1c2;
	--btn-hover: #455d7a;
	--social-color: #eaeaea;
	--social-color-hover: #8fd1c2;
	--tab-color: #1a2b3a;
	--tab-color-active: #8fd1c2;
	--project-bg: rgba(255, 255, 255, 1);
	--navbar-color: #ffffff;
	--icon-color: #8fd1c2;
	--ico-bg: #444447;
	--ico-hover: #525254;
	--bubbles-light-color: #455d7a;
	--bubbles-dark-color: #8fd1c2;
	--highlight-color: #feffdf;
	--highlight-color-text: rgba(51, 64, 81);
}

//Font Size
$fs-075: 0.75rem;
$fs-080: 0.8rem;
$fs-085: 0.85rem;
$fs-090: 0.9rem;
$fs-100: 1rem;
$fs-110: 1.1rem;
$fs-120: 1.2rem;
$fs-150: 1.5rem;
$fs-200: 2rem;
$fs-250: 2.5rem;
$fs-300: 3rem;
$fs-350: 3.5rem;

//Weight
$fw-300: 300;
$fw-400: 400;
$fw-500: 500;
$fw-600: 600;
$fw-700: 700;

//Font Family
$ff-web: 'Titillium Web', sans-serif;
$ff-josefin-blod: 'Josefin Sans', sans-serif;
