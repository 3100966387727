@use '../../styles/mixins' as *;
@use '../../styles/variables' as *;

.project {
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	&__image {
		aspect-ratio: 16/9;
		min-height: 100px;
		background: var(--project-bg);

		img {
			margin-bottom: -15px;
		}
	}

	&__title {
		font-weight: $fw-700;
		display: flex;
		margin-top: 1rem;
		font-size: $fs-110;

		&--link {
			margin-top: 1rem;
			color: var(--primaryLightBlue);
			cursor: pointer !important;
		}

		&--icon {
			font-size: 1rem;
			transition: all ease 0.3s;
			color: var(--primary);

			&:hover {
				color: var(--primaryLightGreen);
			}
		}
	}

	&__body {
		font-weight: $fw-300;
		line-height: 1.3;
		border-top: solid 1px var(--primary);
		padding: 0.5rem 0;
		width: 100%;
	}

	&__tech {
		list-style: none;
		display: flex;
		flex-wrap: wrap;
		gap: 0.5rem;
		margin-top: 1rem;

		&--list {
			background: var(--btn-bg);
			border-radius: 20px;
			padding: 0.3rem 0.7rem 0.1rem;
			color: var(--btn-text);
			font-size: 0.85rem;
		}
	}
}

.yarl__navigation_next,
.yarl__navigation_prev {
	display: none !important;
}
