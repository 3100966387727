@use '../../styles/mixins' as *;
@use '../../styles/variables' as *;

.navbar {
	min-height: 70px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 1.5rem;

	@include tablet {
		padding: 0 2.5rem;
	}

	@include desktop {
		width: 70rem;
		margin: 0 auto;
	}
}

.nav-item {
	list-style-type: none;
}

.nav-menu {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 1rem;
}

.navbar-container {
	display: flex;
	align-items: center;
	gap: 0.5rem;

	@include tablet {
		gap: 2rem;
	}
}

.nav-branding {
	font-family: 'Titillium Web', sans-serif;
	font-size: 2.5rem;
	color: var(--primary);
	letter-spacing: 0.1rem;
	display: flex;
	font-weight: $fw-600;
	align-items: center;
	z-index: 999;
}

.nav-link {
	transition: 0.2s ease;
	color: var(--primary);
	font-size: 1.3rem;
	border: none;
	position: relative;

	@include tablet {
		font-size: 1rem;
	}

	&:hover {
		border: none;
	}

	&::after {
		height: 0px;

		@include tablet {
			content: '';
			position: absolute;
			width: 0px;
			height: 5px;
			left: 50%;
			bottom: -27px;
			background-color: var(--primaryLightGreen);
			transition: all ease-in-out 0.2s;
		}
	}

	&:hover::after {
		width: 100%;
		left: 0;
	}
}

.hamburger {
	z-index: 999;
	display: block;
	cursor: pointer;

	@include tablet {
		display: none;
	}
}

.bar {
	display: block;
	width: 23px;
	height: 2px;
	border-radius: 2px;
	margin: 6px auto;
	transition: all 0.3s ease-in-out;
	background-color: var(--primary);
}

@media only screen and (max-width: 720px) {
	.hamburger.active .bar:nth-child(2) {
		opacity: 0;
	}
	.hamburger.active .bar:nth-child(1) {
		transform: translateY(8px) rotate(45deg);
	}
	.hamburger.active .bar:nth-child(3) {
		transform: translateY(-8px) rotate(-45deg);
	}

	.nav-menu {
		position: fixed;
		left: -100%;
		top: 0px;
		gap: 0;
		flex-direction: column;
		background-color: var(--header-color);
		width: 100%;
		min-height: 100vh;
		text-align: center;
		transition: 0.3s;
		padding: 1.5rem 0;
		z-index: 1;
	}

	.nav-item {
		padding: 16px 0;
		width: 100%;
	}

	.nav-menu.active {
		left: 0;
	}
}
