@use '../../styles/mixins' as *;
@use '../../styles/variables' as *;

.about {
	display: flex;
	flex-direction: row;
	gap: 3rem;
	margin: 10rem 0;

	@include tablet {
		flex-direction: row;
		height: 40vh;
		margin: 10rem 0 20rem;
		justify-content: flex-end;
	}

	&-container {
		width: 100%;

		@include tablet {
			width: 70%;
		}

		&__title {
			font-family: $ff-web;
			font-size: $fs-250;
		}

		&__text {
			font-size: $fs-120;
			font-weight: 300;
			margin-top: 1rem;
			margin-bottom: 1rem;

			@include tablet {
				margin-bottom: 1.5rem;
			}
		}
	}
}
