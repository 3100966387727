@use '../../styles/mixins' as *;
@use '../../styles/variables' as *;

.bubbles-title {
	color: var(--primary);
	font-size: 1.5rem;
	margin-bottom: 1rem;
}

.bubbles-list {
	list-style-type: none;
	display: flex;
	flex-wrap: wrap;
	gap: 1rem;
}

.bubbles-item {
	background: var(--bubbles-light-color);
	border-radius: 1rem;
	font-weight: 400;
	padding: 0.1rem 1.5rem;
	color: #fff;
	font-size: $fs-100;
	transition: background-color 0.3s ease-in-out;
	cursor: pointer;

	&:hover {
		background: var(--bubbles-dark-color);
	}

	&:nth-child(even) {
		background: var(--bubbles-dark-color);

		&:hover {
			background: var(--bubbles-light-color);
		}
	}
}
