@use '../../styles/mixins' as *;
@use '../../styles/variables' as *;

.button-prop {
	font-family: inherit;
	font-size: $fs-100;
	background-color: var(--btn-bg);
	color: var(--btn-text);
	border-radius: 1rem;
	border: none;
	padding: 0.6rem 1.5rem;
	transition: background-color 0.2s ease;
	font-weight: $fw-600;
	cursor: pointer;
	margin-top: 1rem;

	@include tablet {
		margin-top: 0;
	}

	&:hover {
		background-color: var(--btn-hover);
		color: var(--primary);
	}

	&:active {
		background-color: var(--primaryLight);
		color: var(--primaryLightBlue);
	}
}
