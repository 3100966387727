@use '../../styles/mixins' as *;
@use '../../styles/variables' as *;

.project-section {
	&--container {
		&__list {
			list-style-type: none;
			display: flex;
			flex-wrap: nowrap;
			justify-content: space-between;
			flex-direction: column;
			align-content: center;
			align-items: stretch;
			gap: 5rem;

			@include tablet {
				flex-wrap: wrap;
				flex-direction: row;
				gap: 5rem;
			}
		}

		&__element {
			width: calc(100% - 0px);
			padding: 1rem;
			background: var(--project-color);
			box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);

			&:last-child {
				border-bottom: none;
			}

			@include tablet {
				width: calc(50% - 40px);
			}

			@include tablet {
				border-bottom: none;
			}
		}
	}
}

.carousel .control-arrow:hover {
	background-color: var(--primaryLightBlue) !important;
}

.carousel .slide img {
	max-width: 100%;
	height: auto;
}

.carousel .control-dots {
	z-index: 0 !important;
}

.carousel .control-dots .dot {
	box-shadow: none !important;
	background-color: var(--primaryLightBlue) !important;
}

.carousel .control-arrow,
.carousel.carousel-slider .control-arrow {
	z-index: 0 !important;
}

.carousel .control-prev.control-arrow,
.carousel .control-next.control-arrow {
	opacity: 1;
	visibility: visible;
}

.carousel .control-prev.control-arrow:before,
.carousel .control-next.control-arrow:before {
	font-size: 26px;
}
