@use '../../styles/mixins' as *;
@use '../../styles/variables' as *;

.title {
	margin-top: 10rem;
	text-align: left;

	&__head {
		font-family: 'Titillium Web', sans-serif;
		font-size: 2.5rem;
		line-height: 1;

		@include tablet {
			font-size: 3rem;
		}
	}

	&__body {
		margin-top: 1.1rem;
		font-size: $fs-120;
		font-weight: $fw-300;

		@include desktop-sm {
			width: 60%;
		}
	}
}

@media (orientation: landscape) {
	.title__head {
		font-size: 2.5rem;
	}
}
