@use './variables' as *;

@mixin mobile {
	@media screen and (min-width: $mobile-breakpoint) {
		@content;
	}
}

@mixin tablet {
	@media screen and (min-width: $tablet-breakpoint) {
		@content;
	}
}

@mixin desktop-sm {
	@media screen and (min-width: $desktop-sm-breakpoint) {
		@content;
	}
}

@mixin desktop {
	@media screen and (min-width: $desktop-breakpoint) {
		@content;
	}
}
@mixin button {
	font-size: 1rem;
	background-color: var(--btn-bg);
	color: var(--btn-text);
	border-radius: 1rem;
	border: none;
	display: inline-block;
	padding: 0.3rem 1rem;
	transition: all 0.2s ease;
	font-weight: $fw-600;
	cursor: pointer;
}
@mixin hover {
	background: var(--btn-hover);
	color: var(--primary);
}
