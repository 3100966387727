@use '../../styles/mixins' as *;
@use '../../styles/variables' as *;

.theme-icon {
	cursor: pointer;
	z-index: 999;
	display: flex;
	transition: all ease-in-out 0.3s;
}

.fa-ico {
	font-size: 1.5rem;
	cursor: pointer;
	color: var(--primary);
}
