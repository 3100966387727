@use './styles/variables' as *;
@use './styles/fonts' as *;
@use './styles/mixins' as *;

// @import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400&family=M+PLUS+Rounded+1c:wght@300;400&display=swap');

html {
	font-family: 'Josefin Sans', sans-serif;
	box-sizing: border-box;
	scroll-behavior: smooth;
	scroll-padding-top: 2rem;
}

* {
	transition: background-color 0.2s ease-in-out;
}

*,
*::before,
*::after {
	box-sizing: inherit;
	padding: 0;
	margin: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
	display: block;
}

button {
	padding: 0;
	border: none;
	background: none;
	cursor: pointer;
}

body {
	line-height: 1.5;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background: var(--bg-color);
}

img {
	width: 100%;
	height: auto;
	outline: none;
}

a,
a:visited {
	text-decoration: none;
}

mark {
	font-weight: $fw-700;
	color: var(--header-color);
	background-color: var(--primary);
	line-height: 1;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	color: var(--primary);
	text-wrap: balance;
}

p {
	color: var(--primary);
}

.container {
	width: 100%;
}

.header {
	background-color: var(--header-color);
	// box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.1);
}

.main {
	width: 100%;
	padding: 0 1.5rem;

	@include tablet {
		padding: 0 2.5rem;
	}

	@include desktop-sm {
		max-width: 70rem;
		margin: 0 auto;
	}
}

.footer {
	width: 100%;
	padding: 0 1.5rem;
	margin-top: 10rem;
	@include tablet {
		padding: 0 2.5rem;
	}
	@include desktop-sm {
		max-width: 70rem;
		margin: 0 auto;
		height: auto;
		padding-top: 5rem;
	}
}

body::-webkit-scrollbar-track {
	box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
